import {
  faExclamationTriangle,
  faInfoCircle,
  faCheck,
  faCheckCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationColor } from "./models";
import { MouseEvent } from "react";

interface NotificationIconProps {
  onClick?: (e: MouseEvent) => void;
  colorType: NotificationColor;
  isVisible?: boolean;
}

const getIconByColor = (colorType: NotificationColor) => {
  switch (colorType) {
    case "danger":
    case "warning":
    case "warning-100":
      return faExclamationTriangle;
    case "info":
    case "info-100":
      return faInfoCircle;
    case "success":
      return faCheck;
    case "success-100":
      return faCheckCircle;
  }
};

export const NotificationIcon = ({
  onClick,
  colorType,
  isVisible = true,
}: NotificationIconProps) => {
  return isVisible ? (
    <FontAwesomeIcon
      fixedWidth
      icon={getIconByColor(colorType)}
      onClick={onClick}
      className="tw-mr-1"
    />
  ) : null;
};
