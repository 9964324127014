import type { CookieType, NotificationColor } from "./models";
import { NotificationIcon } from "./NotificationIcon";
import { NotificationCloseButton } from "./NotificationCloseButton";
import { useNotificationCookies } from "./useNotificationCookies";
import cx from "classnames";

interface NotificationProps {
  colorType: NotificationColor;
  cookieId?: CookieType;
  text: string;
  showIcon?: boolean;
  showCloseButton?: boolean;
  onClick?: () => void;
  className?: string;
}

const getTextColor = (colorType: NotificationColor) => {
  switch (colorType) {
    case "warning":
      return "tw-text-cs-shade-700";
    case "warning-100":
      return "tw-text-cs-warning-300";
    case "info-100":
      return "tw-text-cs-info";
    case "success-100":
      return "tw-text-cs-success-300";
    default:
      return "tw-text-cs-shade-white";
  }
};

const getBannerClass = (colorType: NotificationColor) =>
  cx(
    "tw-flex",
    "tw-items-center",
    `tw-bg-cs-${colorType}`,
    getTextColor(colorType),
    "tw-text-sm"
  );

const getContentClass = (hasClickHandler: boolean) =>
  cx(
    { "tw-cursor-pointer": hasClickHandler },
    "tw-px-2",
    "tw-py-1",
    "tw-w-full",
    "tw-flex",
    "tw-items-center",
    "tw-gap-x-1"
  );

export const Notification = ({
  colorType,
  cookieId,
  text,
  showIcon = true,
  showCloseButton = true,
  onClick,
  className,
}: NotificationProps) => {
  const { isDisabledByCookie, disableByCookie } =
    useNotificationCookies(cookieId);

  if (isDisabledByCookie) {
    return null;
  }

  const handleClick = () => {
    onClick && onClick();
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    disableByCookie();
  };

  return (
    <div className={cx(getBannerClass(colorType), className)}>
      <div
        className={getContentClass(!!onClick)}
        tabIndex={0}
        onClick={handleClick}
      >
        <NotificationIcon colorType={colorType} isVisible={showIcon} />
        {text}
      </div>
      {showCloseButton && <NotificationCloseButton onClick={handleClose} />}
    </div>
  );
};
